<template>
  <div class="disbursement-list">
    <KTCodePreview v-bind:title="title">
      <template v-slot:toolbar>
        <div>
          <router-link
            :to="{
              name: 'add-disbursement',
            }"
          >
            <b-button variant="primary" size="sm" class="font-weight-bolder">
              <i style="font-size: 1rem" class="flaticon2-add-1"></i> Tạo mới
            </b-button>
          </router-link>
        </div>
      </template>
      <template v-slot:preview>
        <b-row class="mb-5">
          <b-col>
            <b-input
              size="sm"
              placeholder="Id giải ngân"
              v-model="apiParams.id"
              v-on:keyup.enter="onFilter()"
              autocomplete="off"
            />
          </b-col>
          <b-col>
            <b-input
              size="sm"
              placeholder="Tên giải ngân"
              v-model="apiParams.disburseName"
              v-on:keyup.enter="onFilter()"
              autocomplete="off"
            />
          </b-col>
          <b-col>
            <b-input
              size="sm"
              placeholder="Tên file"
              v-model="apiParams.fileName"
              v-on:keyup.enter="onFilter()"
              autocomplete="off"
            />
          </b-col>
          <b-col>
            <b-input
              size="sm"
              placeholder="Ghi chú"
              v-model="apiParams.disburseNote"
              v-on:keyup.enter="onFilter()"
              autocomplete="off"
            />
          </b-col>
          <b-col> </b-col>
        </b-row>
        <b-row class="mb-5">
          <b-col cols="1">
            <b-button
              size="sm"
              variant="primary"
              style="fontweight: 600; width: 70px"
              @click="onFilter()"
              >Lọc</b-button
            >
          </b-col>
        </b-row>
        <div>
          <b-table
            :items="disbursements"
            :fields="disbursementsFields"
            :small="true"
            :busy="onLoading"
            responsive
            bordered
            hover
          >
            <template #cell(disburseName)="data">
              <b>{{ data.item.disburseName }}</b>
            </template>
            <template #cell(actions)="data">
              <b-dropdown size="sm" no-caret right lazy>
                <template slot="button-content">
                  <i
                    style="font-size: 1rem"
                    class="flaticon2-settings pr-0"
                  ></i>
                </template>
                <b-dropdown-item @click="editDisbursement(data.item.id)">
                  <span style="color: #3f4254; font-size: 12px">
                    <i style="font-size: 1rem" class="flaticon2-pen"></i>
                    &nbsp; Chỉnh sửa
                  </span>
                </b-dropdown-item>
                <b-dropdown-item @click="showDeleteAlert(data.item.id)">
                  <span style="color: #3f4254; font-size: 12px">
                    <i
                      style="font-size: 1rem; color: #d33"
                      class="flaticon2-rubbish-bin-delete-button"
                    ></i>
                    &nbsp; Xóa
                  </span>
                </b-dropdown-item>
              </b-dropdown>
            </template>
            <template v-slot:table-busy>
              <vcl-table :speed="5" :animate="true" :columns="10"></vcl-table>
            </template>
          </b-table>
        </div>
        <b-row>
          <b-col>
            <p class="mt-3 text-dark" style="font-weight: 500">
              Tổng số :
              {{ convertPrice(totalItem) }}
            </p>
          </b-col>
          <b-col>
            <b-pagination-nav
              class="customPagination"
              v-if="numberOfPage >= 2"
              :link-gen="linkGen"
              :number-of-pages="numberOfPage"
              use-router
              @change="fetchPreOrders"
              align="right"
              first-class="page-item-first btn btn-icon mr-1 btn-sm my-1"
              prev-class="page-item-prev btn btn-icon mr-1 btn-sm my-1"
              next-class="page-item-next btn btn-icon btn-sm mr-1 my-1 "
              last-class="page-item-last btn btn-icon btn-sm my-1 "
              page-class="btn btn-icon btn-sm border-0 mr-1 my-1"
            >
              <template v-slot:first-text>
                <span>
                  <i class="ki ki-double-arrow-back icon-xs"></i>
                </span>
              </template>

              <template v-slot:prev-text>
                <i class="ki ki-arrow-back icon-xs"></i>
              </template>

              <template v-slot:next-text>
                <i class="ki ki-arrow-next icon-xs"></i>
              </template>

              <template v-slot:last-text>
                <span class="text-info">
                  <i class="ki ki-double-arrow-next icon-xs"></i>
                </span>
              </template>
            </b-pagination-nav>
          </b-col>
        </b-row>
      </template>
    </KTCodePreview>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import ApiService from '@/core/services/api.service';
// import DatePicker from 'vue-bootstrap-datetimepicker';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
// import moment from 'moment';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import { VclTable } from 'vue-content-loading';
import {
  makeToastFaile,
  makeToastSuccess,
  formatDate,
  convertPrice,
} from '@/utils/common';
import { TIME_OUT, TIME_TRIGGER } from '@/utils/constants';
import { cloneDeep, debounce } from 'lodash';
import Swal from 'sweetalert2';

export default {
  data() {
    return {
      dpConfigs: {
        date: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
      },
      apiParams: {
        id: null,
        disburseName: null,
        disburseNote: null,
      },
      title: '',
      disbursements: [],
      onLoading: false,
      numberOfPage: 1,
      totalItem: 0,
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      disbursementsFields: [
        {
          key: 'createdAt',
          label: 'Ngày tạo',
          thClass: 'text-center',
          thStyle: {
            width: '9%',
          },
        },
        {
          key: 'id',
          label: 'ID',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: {
            width: '9%',
          },
        },
        {
          key: 'disburseName',
          label: 'Tên giải ngân',
          thClass: 'text-center',
          thStyle: {
            width: '13%',
          },
          tdClass: 'text-wrap',
        },
        {
          key: 'totalAmount',
          label: 'Tổng tiền',
          thClass: 'text-center',
          tdClass: 'text-center',
          formatter: function (value) {
            return convertPrice(value);
          },
        },
        {
          key: 'disburseNote',
          label: 'Ghi chú',
          thClass: 'text-center',
        },
        {
          key: 'fileName',
          label: 'Tên file',
          thClass: 'text-center',
          thStyle: {
            width: '15%',
          },
        },
        {
          key: 'importDate',
          label: 'Ngày nhập',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'actions',
          label: ' ',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: {
            width: '5%',
          },
        },
      ],
    };
  },
  components: {
    KTCodePreview,
    VclTable,
    // DatePicker,
  },
  computed: {},
  mounted() {
    this.title = 'Danh sách giải ngân';
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.title }]);
  },
  created() {
    this.onInitData();
    this.onFilter();
  },
  methods: {
    formatDate,
    convertPrice,
    onInitData() {},
    debounceInput: debounce((textInput, fn) => {
      fn(textInput);
    }, TIME_TRIGGER),
    onFilter() {
      this.onRefreshPageOne();
      this.fetchPreOrders();
    },
    onRefreshPageOne() {
      this.$route.query.page = 1;
      this.$router.push({
        name: 'list-disbursement',
      });
    },
    onRefresh() {
      this.fetchPreOrders();
    },
    getParamFilters() {
      // //get danh sách status filter
      // const DATE_FORMAT = 'DD/MM/YYYY';
      // const DATE_DB_FORMAT = 'YYYY-MM-DD';
      const apiParams = cloneDeep(this.apiParams);

      return {
        ...apiParams,
      };
    },
    fetchPreOrders: function () {
      if (!this.onLoading) {
        this.onLoading = true;
        this.disbursements = [];
        const params = this.getParamFilters();

        ApiService.query('disbursements', { params }, { timeout: TIME_OUT })
          .then((response) => {
            const dataset = response.data.data;
            this.totalItem = dataset.total;
            this.numberOfPage = dataset.total_page;
            this.disbursements = dataset.data;
            this.onLoading = false;
          })
          .catch((error) => {
            this.onLoading = false;
            this.$nprogress.done();
            if (error.response) {
              makeToastFaile(
                error.response.data ? error.response.data.message : 'Lỗi'
              );
            } else {
              makeToastFaile(error.message || 'Lỗi');
            }
          });
      }
    },
    editDisbursement(id) {
      this.$router.push({
        name: 'update-disbursement',
        params: { id: id },
      });
    },
    removeDisbursement(id) {
      ApiService.delete(`disbursements/${id}`)
        .then((response) => {
          const { status, message } = response.data;
          if (status) {
            makeToastSuccess(message);
            this.onFilter();
          } else {
            makeToastFaile(message);
          }
        })
        .catch((error) => {
          this.onLoading = false;
          this.$nprogress.done();
          if (error.response) {
            makeToastFaile(
              error.response.data ? error.response.data.message : 'Lỗi'
            );
          } else {
            makeToastFaile(error.message || 'Lỗi');
          }
        });
    },
    showDeleteAlert: function (id) {
      Swal.fire({
        title: 'Xóa giải ngân!',
        text: 'Bạn có chắc muốn xóa  giải ngân này không ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      }).then((result) => {
        if (result.value) {
          this.removeDisbursement(id);
        }
      });
    },
  },
};
</script>
<style lang="scss">
.disbursement-list {
  table tbody td {
    vertical-align: middle;
  }
}
</style>
